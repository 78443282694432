<template>
  <div class="forget-3">
    <h1>{{ $t.forgetInterpret.resetPasswordStep3 }}</h1>
    <card class="card">
      <text-field
        v-model="form.password"
        :icon="require('@/assets/img/auth/icon_login_password.png')"
        :placeholder="$t.forgetInterpret.placeholderNewPassword"
        :secureTextEntry="true"
      ></text-field>
      <text-field
        v-model="form.authPassword"
        :icon="require('@/assets/img/auth/icon_login_password.png')"
        :placeholder="$t.forgetInterpret.placeholderAuthPassword"
        :secureTextEntry="true"
      ></text-field>
    </card>
    <div class="btns">
      <div class="btn primary" @click="actionSubmit">
        <span>{{ $t.forgetInterpret.next }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./components/Card";
import TextField from "./components/TextField";
import Schema from "async-validator";
export default {
  name: "ResetPasswordStep3",
  components: {
    Card,
    TextField,
  },
  data() {
    return {
      form: {
        email: "",
        authCode: "",
        password: "",
        authPassword: "",
      },
    };
  },
  created() {
    const { email, authCode } = this.$route.query;
    this.form.email = email || "";
    this.form.authCode = authCode || "";
  },
  methods: {
    actionSubmit() {
      var notMatch = this.$t.forgetInterpret.errorPasswordNotMatch;
      const rule = {
        email: { required: true, message: this.$t.errorParamGoBack },
        authCode: { required: true, message: this.$t.errorParamGoBack },
        password: [
          {
            required: true,
            message: this.$t.forgetInterpret.placeholderNewPassword,
          },
          { min: 6, max: 20, message: this.$t.forgetInterpret.passwordValid },
        ],
        authPassword: [
          {
            required: true,
            message: this.$t.forgetInterpret.placeholderAuthPassword,
          },
          {
            validator(rule, value, callback, source, options) {
              var errors = [];
              if (value !== source.password) {
                const error = new Error(notMatch);
                errors.push(error);
              }
              return errors;
            },
          },
        ],
      };
      const validator = new Schema(rule);
      validator
        .validate(this.form)
        .then((_) => {
          this.$commonMethod.showLoading();
          return this.$api.login.changeUserPassword({
            UserName: this.form.email,
            NewPassword: this.form.password,
            AuthCode: this.form.authCode,
          });
        })
        .then((res) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
            isParams: false,
            path: "auth/forget4",
            name: "Forget4",
            query: [],
          });
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
.forget-3 {
  padding: 2rem 1rem;
  & > h1 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .card {
    margin-top: 15px;
  }

  .btns {
    margin: 43px 25px 60px;

    & > .btn {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $-color-background;
      border-radius: 25px;
      font-size: 15px;
      margin-bottom: 9px;
      border: 0.5px solid $-color-background;

      &.primary {
        background-color: transparent;
        color: white;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-submit {
    height: 50px;
    margin: 35px 25px 0;
    background-color: $-color-background;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 25px;
    font-size: 15px;
  }
  .bottom-action {
    font-size: 14px;
    color: $-color-background;
    text-align: center;
    position: absolute;
    bottom: 25px;
    width: 100%;
    margin: 0 -15px;
  }
}
</style>
