<template>
  <div id="appHome">
    <div class="splash">
      <!-- <div class="splashBtn" @click="todetail">
        <div class="btn">{{t}}{{$t.second}}</div>
      </div>-->
      <img class="splashImg" src="~assets/img/home/splash.png" />
    </div>

    <van-dialog
      v-model="showPrivacy"
      :title="$t.privacy.title"
      @confirm="agree"
      :confirm-button-text="$t.meta.agree"
      @cancel="exitApp"
      :cancel-button-text="$t.meta.disagree"
      show-confirm-button
      :show-cancel-button="env ? true : false"
      theme="round-button"
    >
      <slot name="default">
        <div class="privacy-alert">
          <div class="text1">{{ $t.privacy.text1 }}</div>
          <div class="text2">
            {{ $t.privacy.text2 }}
            <b @click="gotoTerm">
              <u>{{ $t.privacy.text3 }}</u>
            </b>
          </div>
          <div class="text3">{{ $t.privacy.text4 }}</div>
        </div>
      </slot>
    </van-dialog>
  </div>
</template>
<script>
import { isAPP } from "@/plugins/environment/type.js";

export default {
  name: "AppHome",
  data() {
    return {
      showPrivacy: false,
      env: isAPP(), // 当前环境
      isLogin: false,
      nextRoute: "home",
      timers: "",
      t: 3,
    };
  },
  beforeMount() {
    this.$commonEnv.commonAction(this.$commonEnv.actionType.initJpush);//极光推送
    this.$commonEnv.commonAction(this.$commonEnv.actionType.initApprate);//App评分
    let auth = JSON.parse(localStorage.getItem("auth")); //判断是否登录
    if (auth) {
      if (auth.token) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }

      if (auth.IsShopSubmit == 1 && auth.IsShopCheck == 1) {
        this.nextRoute = "home"; //已认证
      } else {
        this.nextRoute = "user/userManage"; //未认证
      }
    }

    let isPrivacy = JSON.parse(localStorage.getItem("isPrivacy")); //隐私政策
    if (!isPrivacy) {
      this.showPrivacy = true;
    } else {
      this.skip();
    }
  },
  methods: {
    // star() {
    //   this.timers = setInterval(() => {
    //     this.t--;
    //     if (this.t == 0) {
    //       this.skip(this.timers);
    //     }
    //   }, 1000);
    // },
    // skip(op) {
    //   clearInterval(op);
    //   this.next();
    // },
    // todetail() {
    //   this.skip(this.timers);
    // },
    skip() {
      this.next();
    },
    next() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: this.isLogin ? this.nextRoute : "auth/login",
        name: "",
        query: [],
      });
    },
    exitApp() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.exitapp);
    },
    gotoTerm() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "terms",
        name: "Terms",
        query: [],
      });
    },
    agree() {
      localStorage.setItem("isPrivacy", true);
      this.skip();
    },
  },
  beforeDestroy() {
    clearTimeout(this.timers);
  },
};
</script>

<style lang="scss" scoped>
.privacy-alert {
  padding: 5%;
  font-size: 14px;
  line-height: 2;
}
.splash {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  .splashBtn {
    position: absolute;
    right: 5%;
    top: 2.3%;
    .btn {
      width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 5px;
      background: rgb(214, 214, 214);
      border-radius: 100%;
      font-size: 14px;
      font-weight: bold;
      white-space: nowrap;
      text-align: center;
    }
  }
  .splashImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>