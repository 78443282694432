<template>
  <div class="text-field">
    <img :src="icon" class="icon" />
    <template v-if="secureTextEntry">
      <input
        class="field"
        :type="showPassword ? 'text' : 'password'"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
      />
      <van-icon
        name="eye-o"
        v-if="showPassword"
        @click="showPassword = !showPassword"
      />
      <van-icon
        name="closed-eye"
        v-else
        @click="showPassword = !showPassword"
      />
    </template>
    <template v-else>
      <input
        class="field"
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
      />
    </template>
    <slot name="accessory" class="accessory"></slot>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon } from "vant";

Vue.use(Icon);

export default {
  name: "TextField",
  props: {
    value: String,
    icon: String,
    placeholder: String,
    secureTextEntry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0 25px;

  .field {
    background: none;
    outline: none;
    border: none;
    flex-grow: 1;
    margin-left: 20px;
  }

  .icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
  }

  .van-icon {
    color: #4ca7a3;
  }
}
</style>
