<template>
  <div class="FollowList">
    <ul class="listBox">
      <li class="item" v-for="(item, index) in listFollow" :key="index">
        <!-- logo -->
        <van-row type="flex" align="center" class="infoHeader">
          <van-col class="infoHeaderLeft">
            <figure class="logo">
              <van-image
                class="logoIcon"
                :src="item.HeadPhoto && imgUrlFilter(item.HeadPhoto)"
              >
                <template v-slot:error>
                  <img
                    class="ImgIcon"
                    src="~assets/img/userManage/user_logo@2x.png"
                  />
                </template>
              </van-image>
            </figure>
            <p class="companyName">{{ item.CompanyName }}</p>
          </van-col>

          <van-col class="infoHeaderRigth">
            <p class="createTime">{{ item.CreateTime }}</p>
          </van-col>
        </van-row>

        <van-row type="flex" align="center" class="infoBox">
          <van-col class="infoBoxLeft" v-if="item.Descriptions">
            <p class="descriptions">{{ item.Descriptions }}</p>
          </van-col>

          <van-row type="flex" class="infoBoxRight">
            <van-col
              :span="8"
              :key="index"
              v-for="(image, index) in item.ProductPhotoList"
              @click="imagePreview(item.ProductPhotoList, index)"
              align="center"
            >
              <figure class="logo">
                <van-image class="logoIcon" :src="image && imgUrlFilter(image)">
                  <template v-slot:error>
                    <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                  </template>
                </van-image>
              </figure>
            </van-col>
          </van-row>
        </van-row>

        <!-- 转发等操作 -->
        <van-row type="flex" align="center" class="priceBox">
          <van-col :span="14">
            <span class="unit">$</span>
            <span class="num">
              {{ Math.round(item.MinPrice * 100) / 100 }}</span
            >
          </van-col>

          <van-col
            class="share"
            :span="10"
            @click="
              handleShareProduct(
                item.ProductNumber,
                item.ProductName,
                item.MinPrice,
                item.ProductPhotoList[0]
              )
            "
          >
            <button class="shareBt">
              <span class="text">{{ $t.forward }}</span>
              <van-icon name="arrow" size="20" />
            </button>
          </van-col>
        </van-row>
      </li>
    </ul>

    <van-dialog
      :title="$t.priceMarkup"
      v-model="showShareProduct"
      @confirm="shareProductConfirm"
      @cancel="showShareProduct = false"
      :confirm-button-text="$t.confirm"
      :cancel-button-text="$t.cancel"
      :show-cancel-button="true"
      :show-confirm-button="true"
    >
      <slot name="default">
        <div class="shareProduct-alert">
          <div class="shareProduct-alert-content">
            <van-radio-group v-model="priceMarkup">
              <van-radio name="0">0%</van-radio>
              <van-radio name="10">+ 10 %</van-radio>
              <van-radio name="20">+ 20 %</van-radio>
              <van-radio name="30">+ 30 %</van-radio>
              <van-radio name="40">+ 40 %</van-radio>
            </van-radio-group>
          </div>
        </div>
      </slot>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon } from "vant";
import { Row, Col, Radio, RadioGroup } from "vant";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

Vue.use(Row).use(Col).use(Image).use(Icon).use(Radio).use(RadioGroup);

export default {
  name: "FollowList",
  props: ["listFollow"],
  components: {},
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
      showShareProduct: false,
      shareForm: {
        id: "",
        name: "",
        price: "",
        image: "",
      },
      priceMarkup: "0",
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    handleShareProduct(id, name, price, image) {
      if (this.isApp && this.platform == "ios") {
        this.shareForm = {
          id: id,
          name: name,
          price: price,
          image: image,
        };
        this.showShareProduct = true;
        this.priceMarkup = "0";
      } else {
        this.shareGoods(id, name, price, image);
      }
    },
    shareProductConfirm() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: this.shareForm.id,
        PriceMarkup: this.priceMarkup,
      };
      this.$api.product
        .editShareProductPrice(param)
        .then(() => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.shareGoods(
            this.shareForm.id,
            this.shareForm.name,
            this.shareForm.price,
            this.shareForm.image
          );
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    shareGoods(id, name, price, image) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      this.$commonEnv.commonAction(this.$commonEnv.actionType.shareGoods, {
        id: id,
        name: name,
        price: price,
        image: image,
        userId: auth.userId,
        shopId: auth.shopId,
        shareUrl:
          "https://shop.vancheen.com/web/details?ProductNumber=" +
          auth.shopId +
          id +
          "&ShopId=" +
          auth.shopId +
          "&BackHome=true",
      });
    },
    //预览图片
    imagePreview(images, index) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.picturePreview, {
        pictureList: images,
        startPosition: index,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.shareProduct-alert {
  padding: 0% 10%;
  line-height: 2;
  .shareProduct-alert-content {
    .van-radio-group {
      margin-top: 10%;
      .van-radio {
        margin-bottom: 10%;
      }
    }
  }
}
.FollowList {
  width: 100%;
  margin-top: 3%;
  .listBox {
    margin: 0% 3%;
    // padding: 36px;
    // box-sizing: border-box;
    // -webkit-box-sizing: border-box;
    .item {
      padding: 15px;
      border-radius: 20px;
      box-shadow: 0 0 0px 1px #ece7e7;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 3%;
    }
  }
  .infoHeader {
    .infoHeaderLeft {
      width: 50%;
      text-align: left;
      .logo {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        .logoIcon {
          .ImgIcon {
            width: 50px;
            height: 50px;
            object-fit: contain;
            border-radius: 100%;
          }
          ::v-deep .van-image__img {
            width: 50px;
            height: 50px;
            position: relative;
            object-fit: contain;
            border-radius: 100%;
          }
          ::v-deep .van-image__error {
            width: 50px;
            height: 50px;
            position: relative;
            object-fit: contain;
            border-radius: 100%;
          }
        }
      }
      .companyName {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        color: #999999;
        font-size: 16px;
      }
    }
    .infoHeaderRigth {
      width: 50%;
      text-align: right;
      .createTime {
        margin-left: 10px;
        color: #999999;
        font-size: 14px;
      }
    }
  }
  .infoBox {
    .infoBoxLeft {
      width: 100%;
      text-align: left;
      font-size: 14px;
      .descriptions {
        margin-left: 10px;
      }
    }
    .infoBoxRight {
      width: 100%;
      .logo {
        margin: 0;
        .logoIcon {
          width: 26.67vw;
          height: 26.67vw;
          margin-top: 5px;
          .ImgIcon {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          ::v-deep .van-image__img {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: contain;
          }
          ::v-deep .van-image__error {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: contain;
          }
        }
      }
    }
  }
  .priceBox {
    color: #ff5852;
    margin: 5px;
    .unit {
      font-size: 16px;
    }
    .num {
      font-size: 20px;
      font-weight: bold;
    }
    .share {
      text-align: right;
      //margin-top: 16px;
      .shareBt {
        padding: 0;
        border: none;
        color: #25b9ff;
        background-color: #ffffff;
        vertical-align: middle;
        text-align: center;
        .van-icon {
          margin-left: 5px;
          vertical-align: middle;
          margin-top: 2px;
        }
        .text {
          vertical-align: middle;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
