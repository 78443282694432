<template>
  <div class="storeList">
    <ul class="listBox">
      <li
        :class="['item', item.SupplierRemovalDes ? 'gray-background' : '']"
        v-for="(item, index) in listProduct"
        :key="index"
      >
        <van-row type="flex" align="center" class="itemTop">
          <van-col class="itemTopTitle" v-if="item.isTranslation == 0">
            <div class="discount" v-if="item.IsCouponProduct">
              {{ $t.discount }}
            </div>
            <div class="brand" v-if="item.IsBrandProduct">
              {{ $t.brand }}
            </div>
            <div class="attestation" v-if="item.IsAttestationProduct">
              {{ $t.attestation }}
            </div>
            <div class="name">
              {{ item.ProductName }}
            </div>
          </van-col>
          <van-col class="itemTopTitle" v-else>{{ item.TProductName }}</van-col>

          <div class="textList" v-if="item.SupplierRemovalDes">
            {{ item.SupplierRemovalDes }}
          </div>
          <div v-else>
            <van-col
              class="putaway"
              v-if="item.Status === 0"
              @click="editB_ProductStatus(item.ProductNumber, 1)"
              >{{ $t.put }}</van-col
            >
            <van-col
              class="shelves"
              v-else-if="item.Status === 1"
              @click="editB_ProductStatus(item.ProductNumber, 0)"
              >{{ $t.sold }}</van-col
            >
          </div>
        </van-row>
        <!-- logo -->
        <van-row
          type="flex"
          align="center"
          class="infoBox"
          @click="godetail(item.ProductNumber)"
        >
          <van-col class="infoBoxLeft">
            <figure class="logo">
              <div class="stickIsTop" v-if="item.IsTop === 1">
                <img
                  class="stickLogo"
                  src="~assets/img/store/icon_management_top@2x.png"
                />
                <div
                  :class="[language == 'zh' ? 'stickText-zh' : 'stickText-en']"
                >
                  {{ $t.stick }}
                </div>
              </div>
              <div class="stickUpdated" v-if="item.IsSupplierUpdated === 1">
                <img
                  class="stickLogo"
                  src="~assets/img/store/icon_management_updated@2x.png"
                />
                <div
                  :class="[language == 'zh' ? 'stickText-zh' : 'stickText-en']"
                >
                  {{ $t.updated }}
                </div>
              </div>
              <van-image
                class="logoIcon"
                :src="item.ProductPhoto && imgUrlFilter(item.ProductPhoto)"
              >
                <template v-slot:error>
                  <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                </template>
              </van-image>
            </figure>
          </van-col>
          <van-col class="infoBoxRigth">
            <p class="infoBoxRigthTitle" v-if="item.isTranslation == 0">
              {{ item.ProductSTitle }}
            </p>
            <p class="infoBoxRigthTitle" v-else>{{ item.TProductSTitle }}</p>
            <p class="sales">{{ $t.sales }}：{{ item.Sales }}</p>
            <section class="typePrice">
              <p class="priceName">{{ $t.sellingPrice }}：</p>
              <p class="num">{{ item.CurrentPrice }}</p>
            </section>
            <p class="inventoryBox">
              {{ $t.totalInventory }} : {{ item.TotalInventory }}
              <span class="inventory-state" v-if="item.ProductState == 2"
                >({{ $t.inventoryState1 }})</span
              >
              <span class="inventory-state" v-if="item.ProductState == 3"
                >({{ $t.inventoryState2 }})</span
              >
            </p>
            <p class="gain">{{ $t.earn }} : {{ item.Profit }}</p>
          </van-col>
        </van-row>

        <!-- 翻译等操作 -->
        <van-row type="flex" align="center" class="operationList">
          <van-col
            class="nostick"
            v-if="item.Status === 0"
            @click="deleteB_Product(item.ProductNumber)"
            >{{ $t.delete }}</van-col
          >
          <van-col
            class="share"
            v-if="item.Status === 1"
            @click="
              handleShareProduct(
                item.ProductNumber,
                item.ProductName,
                item.CurrentPrice,
                item.ProductPhotoList[0]
              )
            "
            >{{ $t.forward }}</van-col
          >
          <van-col
            class="updatePrice"
            @click="getUpdatePrice(item.ProductNumber, item.ShopId)"
            >{{ $t.updatePrice }}</van-col
          >
          <van-col
            class="nostick"
            v-if="item.IsTop === 1"
            @click="editB_ProductTop(item.ProductNumber, 0)"
            >{{ $t.unstick }}</van-col
          >
          <van-col
            class="stick"
            v-else
            @click="editB_ProductTop(item.ProductNumber, 1)"
            >{{ $t.stick }}</van-col
          >
          <van-col
            class="interpret"
            @click="getInterpret(item.ProductNumber, item.ShopId)"
            >{{ $t.interpret }}</van-col
          >
        </van-row>
      </li>
    </ul>

    <van-dialog
      :title="$t.priceMarkup"
      v-model="showShareProduct"
      @confirm="shareProductConfirm"
      @cancel="showShareProduct = false"
      :confirm-button-text="$t.confirm"
      :cancel-button-text="$t.cancel"
      :show-cancel-button="true"
      :show-confirm-button="true"
    >
      <slot name="default">
        <div class="shareProduct-alert">
          <div class="shareProduct-alert-content">
            <van-radio-group v-model="priceMarkup">
              <van-radio name="0">0%</van-radio>
              <van-radio name="10">+ 10 %</van-radio>
              <van-radio name="20">+ 20 %</van-radio>
              <van-radio name="30">+ 30 %</van-radio>
              <van-radio name="40">+ 40 %</van-radio>
            </van-radio-group>
          </div>
        </div>
      </slot>
    </van-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Icon, Row, Col, Button, Radio, RadioGroup } from "vant";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

Vue.use(Row)
  .use(Col)
  .use(Image)
  .use(Icon)
  .use(Button)
  .use(Radio)
  .use(RadioGroup);

export default {
  name: "StoreList",
  props: ["listProduct"],
  data() {
    return {
      language: "",
      isApp: isAPP(),
      platform: getPlatform(),
      showShareProduct: false,
      shareForm: {
        id: "",
        name: "",
        price: "",
        image: "",
      },
      priceMarkup: "0",
    };
  },
  mounted() {
    var localLanguage =
      navigator.language ||
      navigator.userLanguage ||
      localStorage.getItem(kLanguage) ||
      "en";
    this.language = localLanguage.substr(0, 2);
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    //修改上下架
    editB_ProductStatus(ProductNumber, Status) {
      this.$emit("editB_ProductStatus", ProductNumber, Status);
    },
    //置顶与取消置顶
    editB_ProductTop(ProductNumber, IsTop) {
      this.$emit("editB_ProductTop", ProductNumber, IsTop);
    },
    deleteB_Product(ProductNumber) {
      this.$commonMethod.customAlert(
        {
          message: this.$t.alert.deleteText,
          cancelButtonText: this.$t.alert.cancelButtonText,
          confirmButtonText: this.$t.alert.confirmButtonText,
        },
        () => {
          this.$emit("deleteB_Product", ProductNumber);
        },
        () => {}
      );
    },
    handleShareProduct(id, name, price, image) {
      if (this.isApp && this.platform == "ios") {
        this.shareForm = {
          id: id,
          name: name,
          price: price,
          image: image,
        };
        this.showShareProduct = true;
        this.priceMarkup = "0";
      } else {
        this.shareGoods(id, name, price, image);
      }
    },
    shareProductConfirm() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        ProductNumber: this.shareForm.id,
        PriceMarkup: this.priceMarkup,
      };
      this.$api.product
        .editShareProductPrice(param)
        .then(() => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.shareGoods(
            this.shareForm.id,
            this.shareForm.name,
            this.shareForm.price,
            this.shareForm.image
          );
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    //转发方法
    shareGoods(id, name, price, image) {
      let auth = JSON.parse(localStorage.getItem("auth"));
      this.$commonEnv.commonAction(this.$commonEnv.actionType.shareGoods, {
        id: id,
        name: name,
        price: price,
        image: image,
        userId: auth.userId,
        shopId: auth.shopId,
        shareUrl:
          "https://shop.vancheen.com/web/details?ProductNumber=" +
          auth.shopId +
          id +
          "&ShopId=" +
          auth.shopId +
          "&BackHome=true",
        type: 1,
      });
    },
    //翻译
    getInterpret(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "interpret",
        name: "interpret",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    //修改价格
    getUpdatePrice(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "editPrice",
        name: "editPrice",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    godetail(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
@mixin publicBt {
  cursor: pointer;
  -webkit-cursor: pointer;
  border-radius: 30px;
  padding: 5px 15px;
  text-align: center;
}
.shareProduct-alert {
  padding: 0% 10%;
  line-height: 2;
  .shareProduct-alert-content {
    .van-radio-group {
      margin-top: 10%;
      .van-radio {
        margin-bottom: 10%;
      }
    }
  }
}
.storeList {
  width: 100%;
  margin-top: 3%;
  .listBox {
    margin: 0% 3%;
    .gray-background {
      background-color: #e5e5e5 !important;
      border: 0.01rem solid #ff625c;
    }
    .item {
      padding: 20px;
      border-radius: 20px;
      box-shadow: 0 0 0px 1px #ece7e7;
      background-color: #ffffff;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 15px;
      .itemTop {
        .itemTopTitle {
          width: 77%;
          font-size: 14px;
          flex: 1;
          -webkit-flex: 1;
          color: #000000;
          font-weight: bold;
          .discount {
            vertical-align: middle;
            display: inline-block;
            background: red;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .brand {
            vertical-align: middle;
            display: inline-block;
            background: #e6a23c;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .attestation {
            vertical-align: middle;
            display: inline-block;
            background: #22b196;
            color: white;
            padding: 0px 2px;
            border-radius: 2px;
            font-size: 10px;
            margin-right: 5px;
          }
          .name {
            vertical-align: middle;
            display: inline-block;
          }
        }
        .shelves {
          border: 1px solid #999999;
          text-align: center;
          border-radius: 32px;
          padding: 5px 15px;
          color: #999999;
        }
        .putaway {
          border: 1px solid #ff625c;
          text-align: center;
          border-radius: 32px;
          padding: 5px 15px;
          color: #ff625c;
        }
        .textList {
          color: #ff625c;
          font-weight: bold;
          text-align: right;
        }
      }
    }
  }
  .infoBox {
    margin-top: 10px;
    .infoBoxLeft {
      width: 30%;
      .logo {
        margin: 0;
        padding: 0;
        position: relative;
        @include puiblicFlex;
        .logoIcon {
          width: 21.34vw;
          height: 21.34vw;
          .ImgIcon {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
          }
          ::v-deep .van-image__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
          }
          ::v-deep .van-image__error {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
          }
        }
        .stickIsTop {
          z-index: 10;
          position: absolute;
          top: 0;
          left: 0;
          .stickLogo {
            width: 65px;
          }
          .stickText-zh {
            color: #fff;
            position: absolute;
            top: 5px;
            left: 5px;
          }
          .stickText-en {
            color: #fff;
            font-size: 0.3rem;
            position: absolute;
            top: 5px;
            left: 5px;
          }
        }
        .stickUpdated {
          z-index: 11;
          position: absolute;
          top: 0;
          left: 0;
          .stickLogo {
            width: 65px;
          }
          .stickText-zh {
            color: #fff;
            position: absolute;
            top: 5px;
            left: 5px;
          }
          .stickText-en {
            color: #fff;
            font-size: 0.3rem;
            position: absolute;
            top: 1px;
            left: 1px;
          }
        }
      }
    }
    .infoBoxRigth {
      width: 70%;
      font-size: 13px;
      .infoBoxRigthTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      .sales {
        margin: 6px 0;
        padding: 0;
        max-width: 100%;
        @include spanNowrap;
      }
      .inventoryBox {
        margin: 0;
        padding: 0;
        margin-top: 6px;
        font-size: 12px;
        color: #999999;
        .inventory-state {
          margin-left: 10px;
          color: red;
          font-weight: bold;
        }
      }

      .typePrice {
        @include puiblicFlex;
        width: 100%;
        .priceName {
          margin: 0;
          padding: 0;
          max-width: 60%;
          @include spanNowrap;
        }
        .num {
          margin: 0;
          // margin-top: 18px;
          padding: 0;
          color: #ff625c;
          font-weight: bold;
          max-width: 40%;
          @include spanNowrap;
        }
      }
      .gain {
        margin: 0;
        padding: 0;
        margin-top: 6px;
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .operationList {
    margin-top: 15px;
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
    font-size: 12px;
    .interpret {
      color: #25b9ff;
      background-color: #ffffff;
      @include publicBt;
      border: 1px solid #25b9ff;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .stick {
      color: #999999;
      background-color: #ffffff;
      @include publicBt;
      border: 1px solid #999999;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .nostick {
      color: #ffffff;
      background-color: #ff625c;
      @include publicBt;
      border: 1px solid #ff625c;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .updatePrice {
      color: #999999;
      background-color: #ffffff;
      @include publicBt;
      border: 1px solid #999999;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .share {
      color: #ffffff;
      background-color: #01aaa3;
      @include publicBt;
      border: 1px solid #01aaa3;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
