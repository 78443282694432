<template>
  <div class="loginBox">
    <auth-logo />

    <van-tabs @click="onClick" v-model="active" class="loginTabs" animated>
      <van-tab :title="$t.loginInterpret.login"></van-tab>
      <van-tab :title="$t.registerInterpret.register"></van-tab>
    </van-tabs>

    <!-- 表单 -->
    <div class="formBox">
      <div class="inputBox">
        <input
          class="username"
          v-model="auth.username"
          type="text"
          :placeholder="$t.loginInterpret.inputUsername"
        />
      </div>
      <div class="inputBox">
        <input
          class="password"
          v-model="auth.password"
          :type="showPassword ? 'text' : 'password'"
          :placeholder="$t.loginInterpret.inputPassword"
        />
        <van-icon
          name="eye-o"
          v-if="showPassword"
          @click="showPassword = !showPassword"
        />
        <van-icon
          name="closed-eye"
          v-else
          @click="showPassword = !showPassword"
        />
      </div>
      <div class="next" @click="onlogin">{{ $t.loginInterpret.login }}</div>

      <p class="forget" @click="actionForget">
        <span>{{ $t.forgetInterpret.forgottenPassword }}</span>
      </p>
    </div>
  </div>
</template>
<script>
import AuthLogo from "./components/AuthLogo";
import Vue from "vue";
import { SET_AUTH } from "@/store/mutation-types";
import { mapMutations } from "vuex";
import { Tab, Tabs, Icon } from "vant";

Vue.use(Tab).use(Tabs).use(Icon);
export default {
  name: "Login",
  components: {
    AuthLogo,
  },
  data() {
    return {
      active: 0,
      auth: {
        username: "",
        password: "",
      },
      showPassword: false,
    };
  },
  methods: {
    onClick(index) {
      if (index == 1) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "auth/register",
          name: "Register",
          query: [],
        });
      }
    },
    //下一步
    onlogin() {
      if (this.auth.password == "" || this.auth.username == "") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          titleMsg: this.$t.loginInterpret.verificationCodeError,
          type: "danger",
        });
        return false;
      }
      this.$commonMethod.showLoading();
      let param = {
        UserName: this.auth.username,
        Password: this.auth.password,
      };
      this.$api.login
        .userLogin(param)
        .then((res) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: this.$t.logInSuccess,
            type: "success",
          });
          this.SET_AUTH(res.data);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.userToken, {
            token: res.data.Ticket,
          });
          this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
            isParams: false,
            path: "user/usermanage",
            name: "UserManage",
            query: [],
          });
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
    actionForget() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: "auth/forget1",
        name: "Forget1",
        query: [],
      });
    },
    ...mapMutations([SET_AUTH]),
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.loginBox {
  position: relative;
  width: 100%;
  .loginTabs {
    ::v-deep .van-tabs__nav {
      background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep .van-tabs__wrap {
      width: 60%;
      border-radius: 10rem;
      margin: auto;
    }
    ::v-deep .van-tab {
      color: #fff;
      font-weight: bold;
    }
    ::v-deep .van-tab--active {
      border-radius: 10rem;
      background: #fff;
      color: #43cd9a;
    }
    ::v-deep .van-tabs__line {
      display: none;
    }
    ::v-deep .van-tab__text {
      font-size: 1rem;
      line-height: 2;
      overflow: visible;
    }
  }
  //表单
  .formBox {
    width: 85%;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    .formTitle {
      width: 190px;
      border-radius: 50px;
      background-color: #ffffff;
      color: #6dc093;
      height: 24px;
      font-size: 26px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
    }
    .inputBox {
      @include publicFlex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      width: 100%;
      .username,
      .password {
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none; /*去除系统默认的样式*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
        color: #f3faf8;
        font-size: 14px;
        width: 100%;
        line-height: 40px;
        border: 2px solid #b9e1da;
        border-radius: 100px;
        padding: 0 20px;
        box-sizing: border-box;
        &::placeholder {
          color: #f3faf8;
          font-size: 14px;
        }
        &::-webkit-input-placeholder {
          color: #f3faf8;
          font-size: 14px;
        }
      }
      .code {
        width: 400px !important;
      }
      .getCode,
      .codeInfo {
        outline: none;
        border: 0;
        height: 95px;
        line-height: 95px;
        width: 185px;
        background-color: #ffffff;
        color: #6dc093;
        font-size: 24px;
        text-align: center;
        border-radius: 80px;
        overflow: hidden;
      }
      .van-icon {
        position: absolute;
        right: 5%;
        color: #ffffff;
      }
    }
    .next {
      width: 100%;
      border-radius: 50px;
      line-height: 50px;
      text-align: center;
      color: #43cd9a;
      font-size: 16px;
      font-weight: bold;
      background-color: #f3faf8;
      margin-top: 20px;
    }
    .InvitationCode {
      margin-top: 20px;
      font-size: 24px;
      width: 100%;
      height: 96px;
      line-height: 96px;
      border: 1px solid #b9e1da;
      border-radius: 100px;
      padding: 0 47px;
      box-sizing: border-box;
      color: #000000;
      color: rgba(0, 0, 0, 1);
      font-size: 30px;
      font-family: Helvetica;
      font-weight: 400;
      opacity: 0.3;
    }
    .classHome {
      text-decoration: none;
      text-align: center;
      margin-top: 50px;
      &:link,
      :visited,
      :hover,
      :active {
        text-decoration: none;
      }
      .termsTitle {
        margin-top: 100px;
        font-size: 28px;
        color: #ffffff;
      }
    }
    .forget {
      margin-top: 25px;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
