var messages = {
  privacy: {
    title: "نصائح إستخدام خدمات فانشين",
    text1: "شكرا لثقتك,أهلا بك في فانشين!",
    text2:
      " من أجل حماية حقوقك ومصالحك بشكل أفضل، يُرجى قراءة ما يلي بعناية وفهمه بالكامل",
    text3: " اتفاقية خدمة المستخدم الفردي وسياسة الخصوصية ",
    text4:
      "إذا نقرت على ”موافق“، فهذا يعني أنك قرأت وفهمت وقبلت جميع محتويات هذه الاتفاقية بالكامل، وستكون هذه الاتفاقية ملزمة قانوناً. إذا نقرت على ”غير موافق“، يمكنك الخروج من هذه الصفحة.",
  },
  meta: {
    homePage: "الصفحة الرئيسية",
    followPage: "متابعة",
    productDetails: "تفاصيل المنتج",
    productList: "قائمة المنتجات",
    supplierDetails: "تفاصيل المورد",
    earnings: "الإيرادات",
    myStore: "متجري",
    commodityManage: "إدارة السلع",
    indent: "طلبات الشراء",
    operatingData: "بيانات التشغيل",
    indentDetails: "تفاصيل طلبات الشراء",
    withdraw: "سحب",
    search: "بحث عن منتج",
    allProducts: "كل المنتجات",
    interpret: "ترجمة",
    setPrice: "تحديد السعر",
    declaration: "إعلان",
    userPage: "أنا",
    enterMyStore: "دخول المتجر",
    shareMyStore: "مشاركة المتجر",
    editMyStore: "تعديل المتجر",
    sharedPage: "مشترك",
    goCheck: "إذهب للتحقق",
    allPromotions: "جميع العروض الترويجية",
    more: "المزيد >",
    checkNow: "تحقق الأن",
    Profitable: "العناصر المربحة إلى الامام",
    Agreement: "اتفاقية خدمة المستخدم",
    privacy: "سياسة الخصوصية",
    agree: "موافق",
    disagree: "غير موافق",
    submit: "إرسال",
    viewShop: "مشاهدة المزيد",
    videoError:
      "مقطع الفيديو هذا غير متوفر في الوقت الحالي , الرجاء المحاولة في وقت لاحق",
    messageText(value) {
      return "يوجد لديك " + value + " رسائل جديدة";
    },
    storeroom: "العاصفة",
    preferred: "المفضلة",
    discount: "خصم مجاني",
    minSpend: "الحد الأدنى للإنفاق",
    nextworkError: "خطأ في الشبكة",
    filter: "المرشّح",
    allFilter: "جميع الحالات",
    isCouponProduct: "سلع مخفضة",
    isBrandProduct: "السلع ذات العلامة التجارية",
    isAttestationProduct: "السلع المعتمدة",
    attType: "نوع الشهادة",
    cSelect: "الرجاء اختيار"
  },
  messages: {
    messageCenter: "مركز المراسلة",
    isTop: "أعلى",
    unread: "غير مقروءة",
  },
  couponInterpret: {
    state1: "قابل للمطالبة",
    state2: "قابل للمشاركة",
    state3: "مشترك",
    state4: "غير متاح",
    state5: "لا مزيد",
    state6: "تم استخدامها",
    shareTo: "تمت مشاركتها مع",
    goShare: "مشاركة",
    endDate: "وقت الأستحقاق",
    addCoupon: "مطالبة",
    noCondition: "لا يوجد حد ادنى",
    selectionTitle: "الرجاء أختيار المعجب",
    editPriceTxt1: "عناصر الخصم لا يمكن تحريرها، يمكنك نشرها بعد الحذف",
    editPriceTxt2: "يجب أن تكون الارباح غير سلبية",
    condition(value) {
      return "أستخدام ما يصل إلى " + value;
    },
    maxCount(value) {
      return "الحد " + value;
    },
    couponCount(value) {
      return "¥ " + value + " قسيمة شراء";
    },
  },
  okText: "OK",
  resetText: "Reset",
  second: "S",
  name: "الأسم",
  tel: "رقم الهاتف",
  save: "حفظظ",
  done: "تم",
  saveSuccess: "نجاح الحفظ",
  saveFail: "خطأ في الحفظ",
  confirm: "تأكيد",
  cancel: "الغاء",
  delete: "حذف",
  complete: "مكتمل",
  loading: "جار التحميل...",
  telEmpty: "يرجى تعبئة الرقم",
  nameEmpty: "الرجاء تعبئة الأسم",
  confirmDelete: "هل أنت متأكد أنك تريد الحذف؟",
  telInvalid: "رقم الهاتف مشوه",
  forwardToEarn: "أكتسب",
  up: "أعلى",
  sell: "بيع",
  // piece: "قطعة",
  piece(value) {
    if (!value) {
      return "القطع المباعة";
    } else {
      return value + " القطع المباعة";
    }
  },
  noMoreData: "العديد من الأشياء الرائعة قادمة قريبا!",
  productDescription: "وصف المنتج",
  noDetails: "لا يوجد تفاصيل",
  supplier: "مورد",
  in: "تصفح",
  addToStore: "اجمع في متجري",
  savedToStore: "تم الحفظ في متجري",
  forwardGoods: "السلع الآجلة",
  belongToSupplier: "أنت موزع منتظم للموردين",
  notAreToSupplier: "أنت لست موزع منتظم للموردين ",
  attention: "متابعة",
  hasAttention: "مشترك",
  operateSuccessfully: "تم العمل بنجاح",
  forward: "إعادة توجيه",
  priceMarkup:"تسعير المنتج",
  //我的小店
  shopAvatar: "صورة الرمزية للمتجر",
  shopName: "أسم المتجر",
  shopDesc: "وصف المتجر",
  todayTurnover: "معدل مبيعات اليوم",
  todayOrder: "طلبات اليوم",
  visitorsToday: "زوار اليوم",
  earnings: "إيراداتي",
  commodity: "مركز العناصر",
  commodityDeleted: "العناصر المحذوفة",
  indent: "إدارة طلبات الشراء",
  buyerOrder: "طلب المشتري",
  operatingData: "تحليل البيانات",
  discount: "خصم",
  brand: "العلامة التجارية",
  attestation: "الشهادة",
  att1: "شعار العلامة التجارية",
  att2: "خطاب تفويض العلامة التجارية",
  att3: "شهادة تسجيل العلامات التجارية",
  attestations: "الشهادات",
  remark:"ملاحظة",
  coupon: "قسيمة شرائية",
  fans: "معجبين",
  createTime: "إنشاء وقت",
  tutorials: "Vancheen Q & A",
  shareMyStore: " انسخ الرابط والصقه في المتصفح لفتحه ",
  soldOut: "خارج الرف",
  sold: "خارج الرف",
  // putaway: "وضعها جانبا",
  putaway: "على الرف",
  put: "على الرف",
  all: "كل العناصر",
  sellingPrice: "السعر",
  sales: "تم البيع",
  profitAmount: " مقدار الربح ",
  profitScale: "مقياس الربح",
  earn: "اكسب",
  totalInventory: "إجمالي المخزون",
  inventoryState1: "متوفر في المخزن",
  inventoryState2: "غير متوفر في المخزن",
  interpret: "تعديل",
  unstick: "على القمة",
  stick: "في الأعلى",
  updated: "تحديث",
  updatePrice: "تعديل السعر",
  mainHeading: "العنوان الرئيسي",
  subheading: "العنوان الفرعي",
  specification: " الطرد",
  cost: "تكلفة",
  profit: "ألارباح",
  inventory: "المخزون",
  msetKeyValue: "تعيين نسبة مئوية للأرباح",
  allOrders: "كل طلبات الشراء",
  notDeliverGoods: "طلب الشراء المراد إرساله",
  confiscatedGoods: "طلب الشراء المراد تأكيده",
  haveFinished: "طلبات الشراء المكتملة",
  todayData: "بيانات اليوم",
  turnover: " الإيرادات ",
  netIncome: "الأرباح",
  orderQuantity: "طلبات الشراء",
  visitor: "الزوار",
  historicalData: "سجلات البيانات",
  historySearch: "سجل البحث",
  cumulativeTurnover: " الإيرادات التراكمية ",
  cumulativeNetIncome: " الأرباح التراكمية ",
  cumulativeOrder: " طلبات الشراء التراكمية ",
  cumulativeVisitors: " عدد الزوار التراكمي ",
  newProduct: "جديد",
  ladies: "السيدات",
  digital: "رقمي",
  hotSale: "خصومات ساخنة",
  myOrder: "طلبي",
  waitPayment: " انتظار الدفع ",
  waitGoods: " انتظر السلع ",
  haveGoods: " احصل على السلع ",
  unfollow: "الغاء المتابعة",
  cancelCollection: "إلغاء التحصيل",
  profilePhoto: " صورة الملف الشخصي ",
  nickname: "إسم المستخدم",
  myShippingAddress: " عنوان الشحن الخاص بي ",
  logInSuccess: "تم تسجيل الدخول بنجاح",
  logOut: "تسجيل الخروج",
  logisticsStatus: " الحالة اللوجستية ",
  consignee: "المشتري",
  totalItems(value) {
    return "الإجمالي " + value + " العناصر";
  },
  totalAmount: "المبلغ الإجمالي",
  shippingAddress: "عنوان المرسل اليه",
  productTotalPrice: "السعر الإجمالي للمنتج",
  freight: "رسوم التوصيل",
  actualPayment: "الدفع الفعلي",
  total: "إجمالي",
  contactTheShop: "التواصل مع المتجر",
  orderNumber: "رقم طلب الشراء",
  orderTime: "تاريخ الطلب",
  timeOfPayment: "تاريخ الدفع",
  payment: "المبلغ المدفوع",
  product: "المنتج",
  totalPrice: "السعر الإجمالي",
  copy: "نسخ",
  copySuccess: "تم النسخ بنجاح",
  nonsupportCopy: "نسخة غير مدعومة",
  contactSeller: "تواصل مع البائع",
  remindSupplierOfShipment: " تذكير المورد لإرسال العناصر ",
  pleaseTranslate: " يُرجى الترجمة ",
  orderEdit: {
    title: "البند",
    total(val) {
      return "مجموع " + val + " عناصر";
    },
    refundToBuyer: " استرداد الأموال إلى المشتري",
    waitingForTheBuyerToPay: " انتظار المشتري للدفع",
    closeOrder: " إغلاق الطلب",
    WaitingConfirmCollection: " في انتظار تأكيد المشتري على المجموعة ",
    confirmReceipt: " تأكيد الاستلام ",
  },
  earningsInfo: {
    orderIncomes: " طلب الدخل ",
    paymentDeclaration: " إقرار الدفع ",
    paymentWithdrew: " الدفع المسحوب ",
    myBalance: "رصيدي",
    withdraw: "سحب",
    total: "إجمالي الدخل",
    frozen: "مدفوعات مستحقة القبض",
    buyerPayment: "مدفوعات المشتري",
    frozenPayment: "مدفوعات مجمدة",
    collectedPayment: "مدفوعات محصلة",
    paymentRelease: "تحرير المدفوعات",
    balanceWithdraw: "سحب الرصيد",
    skuInfo:
      " أي رسومVancheen سيفرض رسوم بنسبة 0.6% من مدفوعاتك ولن يفرض Wechat للدفع فإن Wechat إذا كنت تستخدم نظام ",
    incomeRecords: "سجلات الدخل",
    withdrawRecords: "سجلات السحب",
    poNo: "رقم طلب الشراء",
    bankName: "إسم البنك",
    banksInfo:
      " ستتم معالجة طلب تحويل الدفع هذا في يوم العمل التالي.",
    amountWithdraw: "مبلغ السحب",
    withdrawNum(value) {
      return "المبلغ المتاح للسحب $ " + value;
    },
    allWithdraw: "سحب الكل",
    withdrawBt: "سحب",
  },
  //登录页面
  loginInterpret: {
    login: "تسجيل الدخول",
    inputUsername: "أدخل البريد الإلكتروني",
    inputPassword: "أدخل كلمة السر",
    userName: "البريد الإلكتروني ",
    password: "كلمة المرور",
    authCode: "رمز التحقق",
    phoneNumber: "رقم الهاتف",
    verificationCodeError: "خطأالتحقق من إسم المستخدم او كلمة السر",
    download: "تحميل الصورة",
    saveText: " اضغط مع الاستمرار على الصورة لحفظها ومشاركتها ",
    copyText: "نسخ ارتباط الدعوة",
  },
  //注册页面
  registerInterpret: {
    register: "التسجيل",
    inputEmail: "أدخل البريد الإلكتروني",
    email: "البريد الإلكتروني",
    code: "رمز البريد الإلكتروني",
    inputCode: "أدخل الرمز",
    next: "التالي",
    tick: "يرجى وضع علامة ",
    rulesAndProtocols:
      " وافقت على اتفاقية خدمة المستخدم الفردي وسياسة الخصوصية ",
    verificationCodeInfo: "تم إرسال رمز التحقق",
    pleaseEnterEmail: "الرجاء إدخال",
    verificationCodeErroe: " خطأ في رمز التحقق أو البريد الإلكتروني ",
  },
  //注册页面
  regInfoInterpret: {
    pasTitle: "كلمة السر",
    inputPaw: "الرجاء إدخال كلمة السر",
    pawInfo: "6-20 حرف مختلط مع أرقام",
    inputName: "أدخل إسم المستخدم",
    nameInfo: "5-25 حرف",
    userName: "إسم المستخدم",
    confirm: "تم",
    correctError: "الرجاء إدخال كلمة السر الصحية أولا ",
    userNameError: "الرجاء إدخال إسم المستخدم الصحيح أولا",
    registerSuccess: "تم التسجيل بنجاح",
    registerInfo: " تم التسجيل بنجاح وتحميل التطبيق ",
    inputInviteCode: "الرجاء إدخال رمز الدعوة (أختياري)",
    inviteCode: "رمز دعوة",
    backLogin: "العودة لتسجيل الدخول",
  },
  //忘记密码
  forgetInterpret: {
    forgetPassword: "نسيت كلمة السر",
    validEmail: " التحقق من صحة عنوان البريد الإلكتروني ",
    setNewPassword: "تعيين كلمة سر جديدة",
    finishPasswrod: "إنتهاء",
    forgottenPassword: "نسيت كلمة السر ؟",
    resetPasswordStep1: "1. تأكيد البريد الإلكتروني المسجل ", //
    resetPasswordStep2: "2.رمز التحقق", //
    resetPasswordStep3: "3.تعيين كلمة سر جديدة", //
    resetPasswordStep4: "4.إنتهاء", //
    next: "التالي",
    noAccountGoRegister: "ليس لديك حساب؟ سجل الأن ",
    backLogin: "العودة لتسجيل الدخول",
    placeholderEmail: "أدخل البريد الإلكتروني",
    placeholderEmailCode: "أدخل الرمز",
    noAccountGoRegister: " ليس لديك حساب؟ سجل الأن ",
    sendAuthCode: "رمز البريد الإلكتروني",
    errorEmail: "بريد إلكتروني خاطئ,أعد المحاولة ",
    errorEmailNotExist: " الحساب غير موجود ",
    placeholderNewPassword: "الرجاء إدخال كلمة السر الجديدة",
    passwordValid: "كلمة السر يجب ان تكون 6-20 حرف مع أرقام",
    placeholderAuthPassword: " يُرجى الدخول مرة أخرى ",
    errorPasswordNotMatch:
      " كلمة المرور الجديدة لا تتطابق مع كلمة مرور التأكيد ",
  },
  //用户中心
  userInterpret: {
    ShippingSource: "مشاركاتي",
    MySupplier: " المورّد الخاص بي ",
    ShopOrder: "طلبات اليوم",
    ShopVisitor: "زوار اليوم ",
    Manage: "إدارة ",
    Settings: "الإعدادات",
    Invite: "رمز الدعوة",
    ProductSell: " بيع المنتجات ",
    editName: "تعديل الإسم",
    inputNewName: "أدخل إسم جديد",
    inputYourName: "أدخل إسمك",
    messageCenter: "الرسائل",
    noName: "بدون إسم"
  },
  support: {
    title: "الدعم الفني",
    dateHour: "ساعات العمل : 9:00-18:00 (GMT +8)",
    dateWeek: "أيام العمل: الإثنين – الجمعة",
    helpCenter: "مركز المساعدة",
    email: "البريد الإلكتروني",
    select: "الرجاء إختيار الخدمة",
    supportOption1: " الشكاوي والاقتراحات ",
    supportOption2: "أخرى",
    enterType: "الرجاء إختيار النوع",
    enterSupport: " الرجاء إدخال المحتوى ",
    enterEmail: "الرجاء إدخال كلمة السر",
    contactWay: " معلومات الاتصال الخاصة بك ",
    supportList: " عرض المحتوى المقدم ",
    serviceType: "نوع الخدمة",
    serviceTitle: " عنوان السؤال ",
    serviceStatus: " حالة المشكلة ",
    serviceTime: " وقت التقديم ",
    serviceDes: "وصف المشكلة",
    serviceContact: "معلومات التواصل",
    servicePosted: "تم النشر بواسطة",
    serviceContent: " محتوى الرد ",
    serviceReplyTime: " زمن الاستجابة ",
    serviceReplyContent: " محتوى الرد ",
    serviceInputReplyContent: " الرجاء إدخال محتوى الرد ",
    copyRight: " حق النشر ",
    currentVersion: "الإصدار الحالي",
    updateVersion: "تحديث الإصدار"
  },
  authenticationInfo: {
    haveAuth: " تم التحقق بنجاح ",
    unAuth: " لم يتم التحقق ",
    onAuth: "جار التحقق",
    reAuth: " فشلت عملية التحقق ",
    passPort: " رقم رخصة القيادة / رقم جواز السفر.",
    inputPassPort: " إدخال رقم رخصة القيادة / رقم جواز السفر.",
    auth: " المصادقة ",
    nameAuth: " المصادقة ",
    checkAuth: " تحقق ",
    goAuth: " الذهاب للتحقق ",
    title:
      " يُرجى تحميل صورة واضحة لجواز سفرك أو رخصة القيادة （الوجه الأمامي والخلفي）",
    yourName: "الإسم الحقيقي",
    yourNamePlc: "إسمك",
    bankName: "إسم البنك",
    bankNamePlc: " يُرجى إدخال اسم البنك الخاص بحسابك.",
    bankAccount: "حساب البنك",
    bankAccountPlc: "رقم",
    iBankNo: " رقم البنك الدولي",
    iBankNoPlc: " يرجى إدخال رقم البنك الدولي الخاص بك",
    swiftCode: " رمز سويفت كود ",
    swiftCodePlc: " رمز سويفت كود ",
    bankAddress: " عنوان البنك ",
    bankAddressPlc: " يُرجى إدخال عنوان البنك الخاص بحسابك.",
    yourPostAdd: " العنوان البريدي ",
    yourPostAddPlc: " العنوان البريدي ",
    done: "تم",
    reason: " السبب ",
    authMessage:
      " لم تكمل التحقق من الاسم الحقيقي، يرجى الانتقال لإكمال التحقق ",
    submitMessage:
      " التحقق من هويتك قيد المراجعة، يرجى الانتظار بصبر ",
    failMessage:
      " فشلت عملية التحقق من هويتك، يرجى إعادة التحقق من هويتك ",
    shopName: "أسم المتجر",
    yourShopName: "الرجاء إدخال أسم المتجر",
  },
  settings: {
    accountDelete: "إلغاء الحساب",
    accountDeleteSuccess: "تم إلغاء تنشيط الحساب بنجاح",
    aboutUs: "بشأننا",
    label1: "مسائل تعديل المعلومات",
    label2: "قضايا تجربة التسوق",
    label3: "قضايا أخرى",
    issue1: "غير قادر على تغيير اسم الحساب",
    issue2: "مسائل الائتمان عبر الهاتف المحمول",
    issue3: "تغيير البريد الإلكتروني",
    issue4: "حساب مكرر",
    issue5: "غير راضٍ عن تسوية المنازعات المتعلقة بالمعاملات",
    issue6: "مشكلات في الدفع تمت مصادفتها",
    issue7: "المخاوف المتعلقة بالأمان والخصوصية",
    issue8: "قضايا أخرى",
    inputText: "الرجاء إدخال مشكلات أخرى",
    nextStep: "الخطوة التالية",
    error: "الرجاء تحديد مشكلة",
    termsText: "لقد قرأت 《التذكيرات الهامة لإلغاء الحساب》 وأوافق عليها"
  },
  searchInfo: {
    general: "جنرال",
    sales: "المبيعات",
    forwards: "إعادة التوجيه"
  },
  alert: {
    cancelButtonText: "إلغاء",
    confirmButtonText: "تأكيد",
    copyButtonText: "نسخ",
    authButtonText: "أذهب",
    deleteText: "متأكد من الحذف ؟",
    shopText: "لم يقم متجرك بتحميل أفاتار المتجر، الرجاء تعيين الأفاتار قبل المشاركة",
    goUpload: "ذهاب إلى التحميل",
    cancellationText: "هل أنت متأكد من تعطيل الحساب؟",
  },
  cStore: {
    hotSale: "تخفيضات ساخنة",
    allProducts: "كل المنتجات",
    sold: "تم البيع",
    pleaseEnterContent: "الجاء إدخال المحتوى",
  },
  plugins: {
    unavailable: " تتطلب هذه الميزة الوصول إلى العميل لاستخدامها ",
    download: "تحميل",
  },
  appRate:{
    title: "هل تمانع في تقييم %@؟",
    message: "لن يستغرق الأمر أكثر من دقيقة ويساعدنا على الترويج لتطبيقنا. شكراً لدعمكم",
    cancelButtonLabel: "لا، شكراً",
    laterButtonLabel: "ذكريني لاحقاً",
    rateButtonLabel: "صنفه الآن",
    yesButtonLabel: "نعم",
    noButtonLabel: "ليس حقاً",
    appRatePromptTitle: 'هل تحب استخدام %@',
    feedbackPromptTitle: 'العقل يعطينا بعض الملاحظات',
  }
};
export default messages;
